@use 'styles/typography';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.link {
  display: flex;
  color: $yds-color-white-opacity-60;
  gap: 0.5rem;
  background: transparent;
  border: none;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  text-align: left;
}

.icon {
  color: inherit;
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  color: inherit;
  @include typography.default-s-bold;
}
