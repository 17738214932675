@use 'styles/breakpoints';
@use 'styles/spacing';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-8;

  @include breakpoints.media(tablet) {
    gap: spacing.$spacing-16;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-8;
  padding: 0;
  list-style: none;
  margin: spacing.$spacing-10 0 0;

  @include breakpoints.media(tablet) {
    margin: 0;
    gap: spacing.$spacing-16;
  }
}

.searchListItemPlaceholder {
  display: block;
  flex: none;
}

.searchPageLinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.25rem;
  padding-bottom: 0.7rem;
}
