@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/zIndex';
@use 'styles/breakpoints';
@use 'styles/typography';

.root {
  position: relative;
  @include zIndex.areena-menu-level;
}

.mobile {
  background: $yds-color-black;
  display: block;

  @include breakpoints.media(tablet-landscape) {
    display: none;
  }
}

.desktop {
  display: none;
  height: 4.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  background: $yds-color-black;

  @include breakpoints.media(tablet-landscape) {
    display: flex;
  }
}

.desktopLeftContainer {
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
  margin-right: 1.5rem;
  height: 2.75rem;
  flex-grow: 1;
  gap: 1.5rem;
}

.list,
.list li {
  display: contents;
}

.desktopRightContainer {
  display: flex;
  align-items: center;
  height: 2.75rem;
  gap: 1.5rem;
  justify-content: flex-end;
}

.headerTopBar {
  display: flex;
  padding: 0.125rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.headerBottomBar {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
  height: 3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: contents;
  }
}

.buttonContainer {
  display: flex;
  height: 2.75rem;
  min-width: 2.75rem;
  text-decoration: none;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: $yds-color-white-opacity-60;
  cursor: pointer;
  padding: 0;
  font: inherit;

  &:hover {
    color: $yds-color-gray-5;
  }
}

.searchButton {
  border-radius: $yds-border-radius-large;

  @include breakpoints.media(tablet-landscape) {
    justify-content: flex-start;
    flex-grow: 1;
  }
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  color: inherit;

  @include breakpoints.media(tablet-landscape) {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.areenaLogoLink {
  display: flex;
  height: 2.75rem;
  align-items: center;
  border-radius: $yds-border-radius-large;
}

.areenaLogo {
  width: 8.375rem;
  height: 1.25rem;
}

.textStyles {
  color: inherit;
  @include typography.default-s-bold;
}

.toggleMenuText {
  display: none;

  @include breakpoints.media(tablet-landscape) {
    display: block;
  }
}

.searchActive {
  color: $yds-color-turquoise-40;
}

.logInText {
  display: none;

  @include breakpoints.media(desktop-small) {
    display: block;
  }
}

.searchBarContainerMobile {
  background: $yds-color-black;
  position: absolute;
  top: 3rem;
  width: 100%;
  padding: 0.5rem 1rem 1.5rem 1rem;
  display: flex;
  justify-content: center;
  z-index: 2;
}

// used in MegaMenu.module.scss to keep the same breakpoints between content and the search bar
@mixin searchBarBreakpoints() {
  @include breakpoints.media(tablet) {
    width: 36rem;
  }
  @include breakpoints.media(desktop-small) {
    width: 42.625rem;
  }
  @include breakpoints.media(desktop) {
    width: 52rem;
  }
}

.searchBarContainer {
  width: 100%;

  @include searchBarBreakpoints();

  @include breakpoints.media(tablet-landscape) {
    margin: 0 1.5rem;
  }
}

.closeContainer {
  display: flex;
  justify-content: flex-end;

  @include breakpoints.media(tablet-landscape) {
    width: 8.375rem;
  }
}

.contentOverlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($yds-color-black-opacity-95, 0.6);
  height: 100%;
  width: 100%;
  visibility: hidden;
}

.visibleOverlay {
  visibility: visible;
  z-index: -1;
}
