@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';
@use 'styles/zIndex';
@use '../Header.module.scss' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.container {
  position: absolute;
  top: 8rem;
  background: $yds-color-black;
  width: 100%;
  padding-bottom: spacing.$spacing-24;
  padding-left: spacing.$spacing-16;
  padding-right: spacing.$spacing-16;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.5);
  @include zIndex.areena-menu-level;

  @include breakpoints.media(tablet-landscape) {
    top: auto;
    padding-top: spacing.$spacing-12;
  }
}

.innerContainer {
  width: 100%;
  row-gap: spacing.$spacing-16;
  display: flex;
  flex-direction: column;

  @include searchBarBreakpoints();

  @include breakpoints.media(desktop-small) {
    row-gap: spacing.$spacing-24;
  }

  div {
    row-gap: spacing.$spacing-16;
  }
}

.categoryLinksContainer {
  display: flex;
  gap: spacing.$spacing-8;
  margin-top: spacing.$spacing-8;
  flex-wrap: wrap;
}

.textLinkContainer {
  display: flex;
  gap: spacing.$spacing-24;
  margin-top: spacing.$spacing-8;
}
