.container {
  position: relative;
  padding-top: 56.25%; // = 1/(16/9)
}

.aspectRatio_1_1 {
  padding-top: 100%; // = 1/(1/1)
}

.aspectRatio_9_13 {
  padding-top: 144.44%; // ≈ 1/(9/13)
}

.image {
  object-fit: cover;
}

.packageCard {
  border-radius: 8px;
}
