@use 'styles/breakpoints';
@use 'styles/typography';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;

$easing: cubic-bezier(0.4, 0, 0, 1);

.link {
  display: block;
  border-radius: $yds-border-radius-large;
}

.imageContainer {
  position: relative;
  padding-top: 56.25%; // = 1/(16/9)

  @include breakpoints.media(tablet) {
    padding: initial;
    height: 5.5rem;
  }

  @include breakpoints.media(desktop-small) {
    height: 6rem;
  }

  @include breakpoints.media(desktop) {
    height: 6.875rem;
  }
}

.image {
  object-fit: cover;
  border-radius: $yds-border-radius-large;
  transition: filter 0.3s $easing;

  .link:hover & {
    filter: brightness(108%);
  }
}

.title {
  @include typography.default-s-bold;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  max-height: 100%;
  padding: spacing.$spacing-16;
  right: 0;
}
